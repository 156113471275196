<template>
  <div class="box">
    <!-- <SATMenu active="5" /> -->
    <div class="container">
      <Heading :content="myTitle"></Heading>
      <hr />
      <div class="form-box">
        <el-form>
          <el-form-item label="Company Code" required>
            <el-input placeholder="Company Code" type="text" v-model="code" />
          </el-form-item>
          <el-form-item>
            <el-button
              type="success"
              :disabled="!code"
              @click="studentJoinClass"
            >
              {{ $t("TeacherCourses.Join Now") }}
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import Company from "@/apis/company";

export default {
  metaInfo() {
    return {
      title: `${this.myTitle} - ${this.CompanyName}`
    };
  },

  components: {},

  mixins: [],

  props: [],
  data() {
    return {
      code: null
    };
  },
  computed: {
    myTitle() {
      return "Join a Company";
    }
  },
  watch: {},

  mounted() {
    if (this.$route.query.code) {
      this.code = this.$route.query.code;
    }
  },

  methods: {
    async studentJoinClass() {
      const res = await Company.teacherJoinCompany(this.code);
      await this.$message({
        message: this.$t("Success!"),
        type: "success"
      });
      this.$router.push({ name: "SATList" });
    }
  }
};
</script>

<style scoped>
.form-box {
  max-width: 550px;
  /* margin: 0 auto; */
}
.box .container {
  padding-top: 1rem;
}
.referral-code span {
  display: inline-block;
  height: 3rem;
  line-height: 3rem;
  padding: 0 1rem;
  font-size: 2rem;
  background: rgb(241, 243, 244);
  border-radius: 4px;
}
</style>
